// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-appux-tsx": () => import("./../src/pages/appux.tsx" /* webpackChunkName: "component---src-pages-appux-tsx" */),
  "component---src-pages-augmentedreality-tsx": () => import("./../src/pages/augmentedreality.tsx" /* webpackChunkName: "component---src-pages-augmentedreality-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interaction-tsx": () => import("./../src/pages/interaction.tsx" /* webpackChunkName: "component---src-pages-interaction-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-pages-videogame-tsx": () => import("./../src/pages/videogame.tsx" /* webpackChunkName: "component---src-pages-videogame-tsx" */),
  "component---src-pages-virtualreality-tsx": () => import("./../src/pages/virtualreality.tsx" /* webpackChunkName: "component---src-pages-virtualreality-tsx" */),
  "component---src-pages-webdesign-tsx": () => import("./../src/pages/webdesign.tsx" /* webpackChunkName: "component---src-pages-webdesign-tsx" */),
  "component---src-pages-work-tsx": () => import("./../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-app-ux-post-index-js": () => import("./../src/templates/AppUxPost/index.js" /* webpackChunkName: "component---src-templates-app-ux-post-index-js" */),
  "component---src-templates-augmented-reality-post-index-js": () => import("./../src/templates/AugmentedRealityPost/index.js" /* webpackChunkName: "component---src-templates-augmented-reality-post-index-js" */),
  "component---src-templates-blog-post-index-js": () => import("./../src/templates/BlogPost/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */),
  "component---src-templates-interaction-post-index-js": () => import("./../src/templates/InteractionPost/index.js" /* webpackChunkName: "component---src-templates-interaction-post-index-js" */),
  "component---src-templates-video-games-post-index-js": () => import("./../src/templates/VideoGamesPost/index.js" /* webpackChunkName: "component---src-templates-video-games-post-index-js" */),
  "component---src-templates-virtual-reality-post-index-js": () => import("./../src/templates/VirtualRealityPost/index.js" /* webpackChunkName: "component---src-templates-virtual-reality-post-index-js" */),
  "component---src-templates-web-design-post-index-js": () => import("./../src/templates/WebDesignPost/index.js" /* webpackChunkName: "component---src-templates-web-design-post-index-js" */)
}

